import * as React from "react"
import PropTypes from "prop-types"
import { ContainerWrapper } from "./style"
import "./layout.css"
import { ThirdPartyTags } from "./thirdPartyTags"
import { LocalizeJs } from "./localizejs"

const Layout = ({ children }) => {
  return (
    <ContainerWrapper>
      <main>{children}</main>
      <LocalizeJs />
      <ThirdPartyTags />
    </ContainerWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
