import styled from "styled-components"
import OpeningBG from "../images/hero.png"
import OpeningBGIpad from "../images/hero-ipad.png"
import OpeningBGmobile from "../images/hero-mobile.png"
import BlurredBG from "../images/blurredbg.png"
export const ContainerWrapper = styled.div`
  section {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    background: #000;
  }
  .hidden {
    display: none;
  }
  .container {
    width: 100%;
    max-width: 1134px;
    margin: 0 auto;
    min-height: 100vh;
    @media screen and (max-width: 1250px) {
      padding: 0 80px;
    }
    //ipad
    @media screen and (min-width: 768px) and (max-width: 768px) {
      padding: 0 110px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 30px;
    }
  }

  .Mui-disabled,
  .MuiSelect-select.Mui-disabled {
    cursor: not-allowed;
  }

  label + .MuiInput-formControl {
    margin-top: 0;
  }
  .ghost-text {
    label {
      color: rgba(255, 255, 255, 0.5);
      margin-top: -5px;
      &.MuiInputLabel-shrink {
        opacity: 0;
      }
    }
  }
  .MuiSelect-icon {
    fill: #6fe6ae;
  }
  .header {
    padding: 80px 0 60px;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 1px solid #979797;
    margin-bottom: 90px;
    @media screen and (max-width: 767px) {
      padding: 40px 0 20px;
      margin-bottom: 60px;
    }
    h1 {
      color: #fff;
      font-size: 90px;
      margin-bottom: -15px;
      img {
        width: 100%;
        max-width: 350px;
      }
      @media screen and (max-width: 767px) {
        font-size: 50px;
        img {
          max-width: 220px;
        }
      }
    }
    p {
      color: #f2ae89;
      font-size: 14px;

      letter-spacing: 2px;
      font-weight: 700;
    }
  }
  h2,
  h4 {
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
  }

  h4 {
    font-size: 36px;
    @media screen and (max-width: 767px) {
      font-size: 22px;
    }
  }

  p {
    color: #fff;
    font-size: 21px;
    &.indicates {
      margin-top: -70px;
      padding-bottom: 50px;
      @media screen and (max-width: 767px) {
        margin-top: -30px;
      }
      img {
        width: 16px;
        height: 16px;
        margin: -15px 10px 0 0;
      }
    }
  }
  .actions {
    text-align: right;
    margin-top: 80px;
    @media screen and (max-width: 767px) {
      text-align: center;
    }

    button {
      background: #6fe6ae;
      width: 100%;
      max-width: 373px;
      height: 65px;
      border-radius: 37px;
      font-size: 20px;
      &:hover {
        background: #041d19;
        color: #6fe6ae;
      }
      &.previous-enrolled {
        font-size: 16px;
      }
    }

    p {
      text-align: right;
      padding-right: 80px;
      padding-top: 10px;
    }
    a {
      color: #fff;
      text-decoration: underline;
      font-size: 16px;
      &:hover {
        color: #6fe6ae;
      }
    }
    &.proactive {
      text-align: center;
      button {
        border-radius: 10px;
        max-width: 200px;
      }
      p {
        text-align: center;
        padding-right: 0;
      }
      .current-page {
        margin-top: 20px;
        svg {
          cursor: pointer;
        }
        span {
          font-size: 24px;
          color: #f2ae89;
          margin: 0 10px;
        }
      }
    }
  }
  .divider-custom {
    padding-bottom: 25px;
  }

  .opening,
  .verification-fail {
    background: url(${OpeningBG}) top center no-repeat;
    padding-bottom: 100px;
    //ipad
    @media screen and (min-width: 768px) and (max-width: 768px) {
      background: url(${OpeningBGIpad}) top right no-repeat;
      padding-bottom: 80px;
    }
    @media screen and (max-width: 767px) {
      background: url(${OpeningBGmobile}) top right no-repeat;
    }
    .actions {
      text-align: unset;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 991px) {
        display: block;
        text-align: center;
        button {
          margin-bottom: 40px;
        }
      }
    }
    h3 {
      color: #fff;
      font-size: 48px;
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
    p {
      a {
        color: #fff;
      }
    }
    .body-text {
      width: 100%;
      max-width: 780px;
    }
  }

  .custom-question-retro {
    margin-bottom: 80px;
  }

  .questions {
    height: 100%;
    background-image: radial-gradient(
      circle,
      rgb(0, 41, 27) 0%,
      rgb(1, 22, 16) 25%,
      rgb(0, 0, 0) 145%
    );
    padding-bottom: 100px;
    margin-top: -200px;
    padding-top: 200px;
    .returning {
      text-align: center;
      margin-top: -150px;
      padding-bottom: 100px;
      @media screen and (max-width: 767px) {
        margin-top: -100px;
        padding-bottom: 50px;
        font-size: 18px;
      }
    }
    .questionaire {
      color: #fff;
      font-size: 30px;
      margin: 0 0 80px;
      line-height: 175%;
      //ipad
      @media screen and (min-width: 768px) and (max-width: 768px) {
        line-height: 200%;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
        line-height: 200%;
      }
      .question-wrapper {
        position: relative;
        margin-bottom: 15px;
        &.cb {
          margin: 10px 0 5px -15px;
          font-size: 24px;
          img {
            width: 15px;
          }
          a {
            color: #fff;
            text-decoration: none;
            border-bottom: 1px solid #fff;
          }
        }
        
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
      .role-info-text {
        font-size: 18px;
        line-height: 125%;
        color: rgb(242, 174, 137);
        margin-top: 10px;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
      img {
        &.role-info {
          width: 22px;
          height: 22px;
          position: relative;
          top: -10px;
          z-index: 99;
          @media screen and (max-width: 767px) {
            top: 2px;
            width: 18px;
            height: 18px;
          }
        }
      }
      .field-wrapper {
        position: relative;
        display: inline;
        span.MuiChip-label {
          max-width: 150px;
          @media screen and (max-width: 767px) {
            max-width: 100px;
          }
        }
        .error {
          position: absolute;
          font-size: 12px;
          width: 200px;
          display: inline-flex;
          left: 4px;
          top: 37px;
          color: red;
          @media screen and (max-width: 767px) {
            top: 18px;
            font-size: 11px;
          }
          &.lang {
            top: 42px;
            @media screen and (max-width: 767px) {
              top: 23px;
            }
          }
        }
        
        .char-left {
          position: absolute;
          top: 35px;
          p {
            font-size: 20px !important;
          } 
        }
        .field-asterisk {
          position: absolute;
          right: -10px;
          top: 8px;
          width: 12px;
          height: 12px;
          &.timeline {
            right: -10px;
          }
          &.text {
            right: 0;
            top: 12px;
            @media screen and (max-width: 767px) {
              top: 5px;
            }
          }

          &.right2 {
            right: 2px !important;
          }

          &.right15 {
            right: 15px !important;
          }

          @media screen and (max-width: 767px) {
            top: 0;
          }

        }
      }
      .MuiInput-underline.Mui-error:after {
        bottom: -2px;
      }
    }
  }
  .MuiAutocomplete-endAdornment {
    svg {
      fill: #6fe6ae;
    }
  }
  .error {
    color: red;
    font-size: 12px;
  }
  .successful {
    height: 100%;
    background-image: radial-gradient(
      circle at bottom left,
      rgb(0, 41, 27) 0%,
      rgb(1, 22, 16) 25%,
      rgb(0, 0, 0) 145%
    );
    h3 {
      font-size: 60px;
      color: #fff;
      font-weight: 400;
      @media screen and (max-width: 767px) {
        font-size: 30px;
      }
    }
    p {
      &.resend-code {
        font-size: 16px;
        margin-top: 20px;
        span {
          border-bottom: 1px solid #fff;
          padding-bottom: 2px;
          margin-left: 10px;
          cursor: pointer;
          &.update-phone {
            margin-left: 0;
          }
        }
      }

      &.resent {
        color: #f2ae89;
      }
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
    .success {
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-end;
      a {
        margin-top: 20px;
        padding-left: 25px;
        width: 100%;
        max-width: 373px;
      }
    }
  }
  .hide-it {
    display: none;
  }

  .question-section {
    margin-bottom: 30px;
    h3 {
      color: rgba(255, 255, 255, 0.6);
      font-weight: 400;
    }
  }
  .MuiChip-root {
    font-family: "LatoRegular";
    @media screen and (max-width: 767px) {
      font-size: 14px;
      margin-top: -10px;
      height: 28px;
      margin-bottom: 12px;
    }
  }

  .proactive h3 {
    color: #6fe6ae;
    font-size: 28px;
  }

  .StepRangeSlider {
    margin: 40px 0 80px;
  }
  .slider {
    .StepRangeSlider__tooltip {
      display: none;
    }
    .StepRangeSlider__thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: rgba(240, 202, 93, 1);
      border: 0;
    }
    .StepRangeSlider__track {
      height: 15px;
      border: none;
      background: linear-gradient(
        90deg,
        rgba(232, 177, 143, 0.86) 0%,
        rgba(240, 202, 93, 1) 50%,
        rgba(142, 227, 178, 1) 100%
      );
    }
    .rating {
      display: flex;
      font-size: 22px;
      margin-top: -60px;
      margin-bottom: 80px;
      text-align: center;
      line-height: 100%;
      justify-content: space-between;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  .terms-container {
    margin-bottom: 15px;
    font-size: 18px;
    color: #fff;
    a {
      font-size: 18px;
      color: #fff;
      text-decoration: none;
      border-bottom: 1px solid #fff;
      padding-bottom: 1px;
      padding-left: 0 !important;
      &:hover {
        color: #6fe6ae;
        border-bottom: 1px solid #6fe6ae;
      }
    }
  }
  svg {
    &.dropdown-icon {
      fill: #fff;
      position: absolute;
      width: 14px;
      top: 40px;
      left: 25px;
    }
  }
  p {
    &.change-country {
      font-size: 15px;
      padding-top: 8px;
    }
  }
  .interest {
    .interests-top-wrapper {
      p {
        font-size: 32px;
      }
    }
    .question-wrapper {
      margin-bottom: 30px !important;
      &.label-con {
        margin-bottom: 10px !important;
      }
    }
    .flex {
      display: flex;
      .label-wrapper {
        position: relative;
        .not-at-all {
          position: absolute;
          font-size: 18px;
          line-height: 125%;
          right: 0;
          top: -45px;
          text-align: center;
        } 
      }
      > div {
        &:first-child {
          width: 65%;
        }
        &:last-child {
          width: 30%;
        }
        &.label {
          display: flex;
          padding-left: 24px;
          position: relative;
          span {
            width: 51px;
            font-size: 18px;
            margin-top: -30px;
          }
          .very-interested {
            position: absolute;
            font-size: 18px;
            line-height: 125%;
            right: -45px;
            top: -45px;
            text-align: center;
          } 
        }
      }
    }
    .Mui-disabled {
      .MuiSvgIcon-root {
        fill: grey;
    }
    
  }
  
`

export const ModalWrapper = styled.div`
  background: url(${BlurredBG}) left bottom no-repeat #000;
  padding: 80px 50px 50px;
  position: relative;
  text-align: center;
  svg {
    fill: #fff;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
  button {
    width: 100%;
    background: #6fe6ae;
    border-radius: 37px;
    font-size: 20px;
    max-width: 260px;
    height: 45px;
    margin: 40px auto 20px;
    &:hover {
      background: #041d19;
      color: #6fe6ae;
    }
  }
  .prompt {
    text-align: left;
    h3,
    p {
      color: #fff;
    }
    p {
      font-size: 18px;
    }
    .actions {
      display: flex;
      justify-content: space-between;
      button {
        text-transform: none;
        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
        &:first-child {
          margin-right: 30px;
        }
      }
    }
  }
`

export const Modal = styled.div`
  .close {
    float: right;
    cursor: pointer;
  }
  p {
    font-size: 13px;
    margin-bottom: 15px;
    span {
      font-weight: 700;
    }
  }
  ul {
    li {
      margin-bottom: 10px;
      font-size: 13px;
      list-style: none;
      span {
        font-weight: 700;
      }
    }
  }
`

export const PopoverWrapper = styled.div`
  h6,
  p {
    color: #000;
  }
  h6 {
    margin-bottom: 15px;
  }
  p {
    font-size: 14px;
    line-height: 150%;
    margin: 0;
  }
`
