import React from "react"

export const LocalizeJs = () => {
  return (
    <>
      <script src="https://global.localizecdn.com/localize.js"></script>

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `!function(a){if(!a.Localize){a.Localize={};for(var e=["translate","untranslate","phrase","initialize","translatePage","setLanguage","getLanguage","getSourceLanguage","detectLanguage","getAvailableLanguages","untranslatePage","bootstrap","prefetch","on","off","hideWidget","showWidget"],t=0;t<e.length;t++)a.Localize[e[t]]=function(){}}}(window);`,
        }}
      />

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `Localize.initialize({
            key: 'z2zzR9cWjpRol',
            rememberLanguage: true,
          });`,
        }}
      />
    </>
  )
}
